import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/project.scss"
import { Link } from "@chakra-ui/react"
import { IoIosLink } from "react-icons/io"
export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  const { title, date, description, readMoreText, readMoreLink } = frontmatter

  return (
    <Layout>
      <Seo title="Steen Lauth" />
      <div className="project">
        <div className="blog-post">
          <h1>{title}</h1>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <h2>{date && `${date} `}</h2>
            {readMoreLink && (
              <Link>
                <a target="_blank" rel="noreferrer" href={readMoreLink}>
                  {readMoreText}
                  <IoIosLink />
                </a>
              </Link>
            )}
          </div>
          <p>{description}</p>

          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        slug
        title
        description
        readMoreText
        readMoreLink
      }
    }
  }
`
